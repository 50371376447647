export default [
  /* 0 */ {
    mapID: 'b41eedfd-d51b-45b2-b9a8-530b2af8283f',
    parameters: {depthTest: false},
  },
  /* 1 */ {
    mapID: 'b41eedfd-d51b-45b2-b9a8-530b2af8283f',
    parameters: {depthTest: false},
    orbit: true
  },
  /* 2 */ {
    mapID: '3dbae51d-21cf-4826-b7da-1a06811143de',
    props: {weightsTextureSize: 256}
  },
  /* 3 */ {
    mapID: '8ab1fcac-2e2a-48da-8a2f-0d47a00e8c4d',
    orbit: true,
    props: { coverage: 0.7 }
  },
  /* 4 */ {
    mapID: '2e4cb471-1537-4924-9b60-2f78424184ff',
  },
  /* 5 */ {
    mapID: 'fd0d27e2-c4f8-4ae1-b169-081caa386be2',
  },
  /* 6 */ {
    mapID: '7bc9977a-193e-4641-ab89-f3588920e27b',
    props: { coverage: 0.7 }
  },
  /* 7 */ {
    mapID: 'af4aaedf-69ce-4210-9093-9d8e5d8d088b',
    props: { coverage: 0.7 }
  }
];
